import(/* webpackMode: "eager" */ "/tmp/build_37769c5c/app/AppLogic.tsx");
import(/* webpackMode: "eager" */ "/tmp/build_37769c5c/node_modules/next/dist/client/link.js");
import(/* webpackMode: "eager" */ "/tmp/build_37769c5c/node_modules/next/dist/client/script.js");
import(/* webpackMode: "eager" */ "/tmp/build_37769c5c/node_modules/swiper/swiper.min.css");
import(/* webpackMode: "eager" */ "/tmp/build_37769c5c/node_modules/swiper/modules/effect-cards/effect-cards.min.css");
import(/* webpackMode: "eager" */ "/tmp/build_37769c5c/public/fontawesome/css/all.min.css");
import(/* webpackMode: "eager" */ "/tmp/build_37769c5c/src/Components/BannerForm/BannerForm.component.tsx");
import(/* webpackMode: "eager" */ "/tmp/build_37769c5c/src/Components/Header/Header.component.module.css");
import(/* webpackMode: "eager" */ "/tmp/build_37769c5c/src/Components/ClientList/ClientList.component.module.css");
import(/* webpackMode: "eager" */ "/tmp/build_37769c5c/src/Components/Footer/Footer.component.module.scss");
import(/* webpackMode: "eager" */ "/tmp/build_37769c5c/src/Components/Header/Header.cp.tsx");
import(/* webpackMode: "eager" */ "/tmp/build_37769c5c/src/Components/Img/Img.tsx");
import(/* webpackMode: "eager" */ "/tmp/build_37769c5c/src/Components/Sidebar/Sidebar.component.tsx");
import(/* webpackMode: "eager" */ "/tmp/build_37769c5c/src/Global/Reset.css");
import(/* webpackMode: "eager" */ "/tmp/build_37769c5c/src/Global/App.css");
import(/* webpackMode: "eager" */ "/tmp/build_37769c5c/src/Components/LatestArticles/LatestArticles.component.module.css");
import(/* webpackMode: "eager" */ "/tmp/build_37769c5c/src/Svg/Logo.tsx");
import(/* webpackMode: "eager" */ "/tmp/build_37769c5c/src/Utils/Jotai.tsx");
import(/* webpackMode: "eager" */ "/tmp/build_37769c5c/src/Utils/SmartAwait.ts")